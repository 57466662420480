import React, { lazy, Suspense, useMemo } from 'react';
import { BrowserRouter, Outlet, Route, Routes, useLocation } from "react-router-dom";
import { createTheme, CssBaseline, LinearProgress, responsiveFontSizes, ThemeProvider, Toolbar, Typography, useMediaQuery } from '@mui/material';
import { Nav } from './components/Nav';
import './App.css';
import SendMessages from './pages/SendMessages';

const Home = lazy(() => import('./pages/Home'));


const pageVariants = {
  initial: {
    opacity: 0
  },
  in: {
    opacity: 1
  },
  out: {
    opacity: 0
  }
};



function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const theme = useMemo(() => responsiveFontSizes(
    createTheme({
      palette: {
        info: { main: '#000' },
        mode: true ? 'dark' : 'light'
      },
      typography: {
        fontFamily: 'Coiny'
        // fontFamily: "'Montserrat', sans-serif"
      }
    })
  ), [prefersDarkMode]);

  return (
    <BrowserRouter>

      <ThemeProvider theme={theme}>
        <CssBaseline />
        <>
          <Suspense fallback={<LinearProgress />}>
            <Routes>
              <Route path="/" element={<Nav />}>
                <Route index element={<Home />} />
                <Route path="messages" element={<SendMessages />} />
              </Route>
            </Routes>
          </Suspense>
        </>
      </ThemeProvider>
    </BrowserRouter>

  );
}

export default App;
