import NotificationIcon from '@mui/icons-material/Notifications';
import { AppBar, Avatar, Box, Button, Container, Icon, IconButton, Toolbar, Tooltip, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import { getToken } from "firebase/messaging";
import { messaging } from "../firebase";

interface Page {
    name: string;
    url: string;
    external?: boolean;
}

function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}


const useStyles = makeStyles()((theme) => ({
    appBar: {
        display: 'flex'
    },
    pages: {
        justifyContent: 'end',
    },
    outlet: {
        marginTop: 60,
        zIndex: 10000,
        height: 'calc(100vh)',
        [theme.breakpoints.only('xs')]: {
            marginTop: 57,
        }
    },
    video: {
        position: 'fixed',
        right: 0,
        bottom: 0,
        minWidth: '100%',
        minHeight: '100%'
    },
    buttons: {
        color: 'black',
        borderColor: 'black',
        '&:': {
            borderColor: 'black',
        }
    }
}));

const vapidKey = {
    "publicKey": "BNXmo8UiR4mgCBNVydCcopUFcDj18IBgvK95QY5zpR89PNhRdCKxN8u08AjuyXEcLehohRD8cRfgPbsTvGZ3msg",
}

export function Nav() {
    const { classes } = useStyles();
    const navigate = useNavigate();
    const navigateTo = useCallback((page: Page) => {
        if (page.external) {
            window.location.href = page.url;
            return;
        }
        navigate(page.url);
    }, [navigate]);

    async function requestPushPermission() {
        console.log('request perms')
        //requesting permission using Notification API
        const permission = await Notification.requestPermission();

        if (permission === "granted") {
            await navigator.serviceWorker.ready;
            console.log('Try and get service worker')
            navigator.serviceWorker.getRegistration().then(registration => {
                console.log('reg', registration);
                if (registration) {
                    registration.pushManager
                        .subscribe({
                            userVisibleOnly: true,
                            applicationServerKey: urlBase64ToUint8Array(vapidKey.publicKey),
                        })
                        .then((subscription) => {
                            console.log(subscription); // This will log fcm endpoint!
                            fetch(`https://api.gitgud.wiki/push/set`, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    sub: subscription
                                })
                            }).then(() => console.log('Subscription made'))
                        });
                }

            });
            console.log('Gone pass the block of code')

        } else if (permission === "denied") {
            //notifications are blocked
            alert("You denied for the notification");
        }
    }

    return (
        <>
            <AppBar position="fixed" color="primary">
                <Container maxWidth="xl" >
                    <Toolbar disableGutters className={classes.appBar}>
                        <Typography variant="h6" sx={{ flex: 1 }} onClick={() => navigate('/')}>GitGud</Typography>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} className={classes.pages}>

                        </Box>
                        {window.matchMedia('(display-mode: standalone)').matches && Notification.permission !== 'granted' && (
                            <IconButton
                                onClick={requestPushPermission}
                            >
                                <NotificationIcon />

                            </IconButton>
                        )}

                    </Toolbar>
                </Container>
            </AppBar>
            <div className={classes.outlet}>
                <Outlet />

            </div>

        </>
    );
}